<template>
  <div>
    <div class="page-header d-flex justify-content-between align-items-center">
      <div>
        <h3 class="page-header-title">
          Announcements
        </h3>
        <span class="subtitle d-none d-md-inline">
          Make announcements from here
        </span>
      </div>
      <button class="btn bg-green" @click="modals.newAnnouncementModal = true">
        New Announcement
      </button>
    </div>
    <div class="page-content">
      <div class="text-center mt-3 mb-3" v-if="ui.loading === true">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
      <div class="row" v-else>
        <div class="col-12 col-md-6">
          <div class="ann">
            <div class="d-flex justify-content-between">
              <div>
                <span class="ann-status sent">
                Sent
              </span>
                <h4 class="ann-title">
                  Welcome TEST
                </h4>
              </div>
              <button class="btn btn-control" @click="editAnn(1)">
                <img src="@/assets/icons/icon-edit-normal.svg" alt="Edit">
              </button>
            </div>
            <div class="ann-message">
              <p>
                Dear Employees, We are proud to announce that we will be moving soon to our brand new office. As of
                August 26, 2020, please note…
              </p>
            </div>
            <div class="ann-agents">
              <img src="@/assets/icons/Nathan@2x.png" alt="Agent Avatar">
              <img src="@/assets/icons/Nathan@2x.png" alt="Agent Avatar">
              <img src="@/assets/icons/Nathan@2x.png" alt="Agent Avatar">
              <img src="@/assets/icons/Nathan@2x.png" alt="Agent Avatar">
              <img src="@/assets/icons/Nathan@2x.png" alt="Agent Avatar">
              <span class="ann-total-agents">
                +5
              </span>
            </div>
            <div class="ann-status-details">
              Sent on Oct, 19 2020 07:43
              <span>Login</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <transition name="component-fade" mode="out-in">
      <new-announcement-modal v-if="modals.newAnnouncementModal === true"
                              @close="modals.newAnnouncementModal = false" />
    </transition>

    <transition name="component-fade" mode="out-in">
      <edit-announcement-modal v-if="modals.editAnnouncementModal === true"
                               @close="modals.editAnnouncementModal = false" />
    </transition>
  </div>
</template>

<script>
import NewAnnouncementModal from "./modals/NewAnnouncementModal";
import EditAnnouncementModal from "./modals/EditAnnouncementModal";

export default {
  name: 'Announcements',
  components: {EditAnnouncementModal, NewAnnouncementModal },
  data() {
    return {
      modals: {
        newAnnouncementModal: false,
        editAnnouncementModal: false
      },
      ui: {
        loading: false,
      },
      annId: null
    }
  },
  methods: {
    getData() {
      this.ui.loading = true;

      setTimeout(() => {
        this.ui.loading = false;
      }, 1000)
    },
    editAnn(val) {
      this.annId = val;
      this.modals.editAnnouncementModal = true;
    }
  },
  created() {
    this.getData();
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  .btn {
    &.bg-green {
      @media screen and (max-width: 568px) {
        line-height: 12px;
      }
    }
  }
}

.page-content {
  padding: 24px 30px;
}
.ann {
  padding: 18px 30px;
  border: 1px solid rgba(218, 218, 218, 1);
  border-radius: 14px;
  background-color: rgba(255, 255, 255, 1);

  &-status {
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 9px;
    &.sent {
      color: rgba(0, 171, 52, 1);
    }
  }

  .btn-control {
    border-radius: 8px;
    background-color: rgba(0, 0, 0, 0.05);
    &:hover {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }

  &-title {
    color: rgba(0, 0, 0, 1);
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 27px;
    margin-bottom: 9px;
  }

  &-message {
    color: rgba(0, 0, 0, .7);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;

    & > * {
      color: rgba(0, 0, 0, .7);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
  }

  &-agents {
    display: flex;

    img {
      height: 32px;
      width: 32px;
      border: 2px solid rgba(238, 238, 238, 1);
      border-radius: 50%;

      &:nth-child(n + 2) {
        margin-left: -10px;
      }
    }
  }

  &-total-agents {
    height: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #eee;
    border-radius: 18px;
    background-color: #d8d8d8;
    margin-left: -10px;
    color: rgba(0, 0, 0, 1);
    font-size: 12px;
    letter-spacing: 0;
    line-height: 18px;
  }

  &-status-details {
    margin-top: 12px;
    color: #a3a4a7;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    span {
      color: #000;
    }
  }
}
</style>
