<template>
  <div class="modal" id="newAnnModal">
    <div class="modal-bg" @click="$emit('close')"></div>
    <div class="modal-block">
      <div class="modal-header">
        <h3>
          New Announcement
        </h3>
      </div>
      <div class="modal-body">
        <div class="modal-body-container">
          <div class="modal-body-container_title">Announcement</div>
          <div class="form-group full-width">
            <label for="fieldName">Name</label>
            <input type="text" class="form-control" id="fieldName" v-model="formData.name">
          </div>
          <div class="form-group full-width mt-3">
            <label for="fieldMessage">Message</label>
            <div id="fieldMessage">
              <ckeditor :editor="editor" :config="editorConfig" v-model="formData.editorData"/>
            </div>
          </div>
        </div>
        <div class="modal-body-container border-0">
          <div class="modal-body-container_title">Send to</div>
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="form-group full-width">
                <label for="fieldUsers">Users</label>
                <multiselect v-model="formData.users"
                             :options="users"
                             id="fieldUsers"
                             class="full-width"
                             :searchable="true"
                             :close-on-select="true"
                             :show-labels="false"/>
              </div>
            </div>
            <div class="col-12 col-md-6">
              <label for="fieldMessageAs">Send Message as</label>
              <div id="fieldMessageAs">
                <div class="form-check">
                  <input type="text" class="form-check-input" id="fieldLoginPopup" v-model="formData.loginPopup">
                  <label for="fieldLoginPopup">Login Popup</label>
                </div>
                <div class="form-check mt-2">
                  <input type="text" class="form-check-input" id="fieldLoginEmail" v-model="formData.email">
                  <label for="fieldLoginEmail">Email</label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer d-flex justify-content-between">
        <div class="d-flex align-items-center">
          <button class="btn bg-green" @click="sendNow">
             <span class="spinner-border spinner-border-sm"
                   role="status" aria-hidden="true"
                   v-if="ui.sendNow">
            </span>
            <span v-else>Send Now</span>
          </button>
          <button class="btn btn-control btn-outline-dark ms-1">
            <img src="@/assets/icons/icon-calender.svg" alt="Calender">
          </button>
        </div>
        <button class="btn btn-primary" @click="saveAnn">
          <span class="spinner-border spinner-border-sm"
                role="status" aria-hidden="true"
                v-if="ui.savingAnn">
          </span>
          <span v-else>Save</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Multiselect from 'vue-multiselect';

export default {
  name: 'NewAnnouncementModal',
  components: {Multiselect},
  data() {
    return {
      formData: {
        name: '',
        editorData: '',
        users: '',
        loginPopup: false,
        email: false
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: [['Bold'], ['Link'], ['Italic'], ['list']],
        height: 700
      },
      users: [],
      ui: {
        savingAnn: false,
        sendNow: false,
        loading: false
      }
    }
  },
  methods: {
    saveAnn() {
      this.ui.savingAnn = true;

      setTimeout(() => {
        this.ui.savingAnn = false;
      }, 1000);
    },
    sendNow() {
      this.ui.sendNow = true;

      setTimeout(() => {
        this.ui.sendNow = false;
      }, 1000);
    }
  }
}
</script>

<style lang="scss">
#newAnnModal {
  #fieldMessage {
    .ck-content {
      min-height: 200px;
    }
  }

  .form-check {
    input {
      height: 18px;
      width: 100%;
      max-width: 18px;
      border-radius: 4px;
    }

    label {
      color: rgba(0, 0, 0, 1);
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      margin: 0 0 0 11px;
      font-weight: normal;
    }
  }

  .modal-footer {
    button {
      &.btn-control {
        width: 36px;
        height: 36px;

        img {
          width: 24px;
          height: 24px;
        }
      }

      &.bg-green {
        max-width: 140px;
        width: 140px;
      }

      &.btn-primary {
        max-width: 120px;
        width: 120px;
      }
    }
  }
}

</style>
